<template>
	<div>
		<page-title></page-title>
		<b-card class="mt-1" title="用户信息">
			<user-edit-tab-account :user-data="userData" />
		</b-card>
		<b-card title="此用户管理的项目">
			<user-edit-tab-information :user-data.sync="userData" />
		</b-card>
	</div>
</template>

<script>
	import { computed, onMounted, reactive, toRefs } from "@vue/composition-api";
	import store from "@/store";
	import UserEditTabAccount from "./UserEditTabAccount.vue";
	import UserEditTabInformation from "./UserEditTabInformation.vue";
	import PageTitle from "@/views/layout/PageTitle";
	import { showToast } from "@/libs/utils/showToast";

	export default {
		props: {
			id: [String, Number],
		},
		components: {
			PageTitle,
			UserEditTabAccount,
			UserEditTabInformation,
		},
		setup(props) {
			const blankUserData = {
				user_id: 0,
				user_name: "新用户",
				user_alias: "",
				user_avatar: "",
				user_data: "",
				password: "",
				email: "",
				phone: "",
				description: "",
				update_time: "",
			};
			const event = reactive({
				user_id: computed(() => {
					return props.id;
				}),
				userData: blankUserData,
			});

			const getUserData = () => {
				if (event.user_id) {
					store
						.dispatch("users/fetchUser", event.user_id)
						.then((response) => {
							event.userData = response.data;
						})
						.catch((error) => {
							showToast(error);
						});
				}
			};

			onMounted(() => {
				getUserData();
			});

			return {
				...toRefs(event),
			};
		},
	};
</script>

<style></style>
