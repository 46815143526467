var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"asigned-project","visible":_vm.isAsignedProjectSidebarActive,"backdrop":"","bg-variant":"white","no-header":"","right":"","shadow":"","sidebar-class":"sidebar-lg"},on:{"change":function (val) { return _vm.$emit('update:is-asigned-project-sidebar-active', val); },"hidden":_vm.resetForm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"FolderPlusIcon","size":"16"}}),_vm._v(" 分配项目 ")],1),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2"},[_c('validation-provider',{attrs:{"name":"Project_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"项目名称","label-for":"Project_name"}},[_c('v-select',{attrs:{"options":_vm.projectList,"reduce":function (project) { return project.project_id; },"state":errors.length > 0 ? false : null,"label":"project_name"},on:{"input":function($event){return _vm.selectProject(_vm.blankUserData.project_id)}},model:{value:(_vm.blankUserData.project_id),callback:function ($$v) {_vm.$set(_vm.blankUserData, "project_id", $$v)},expression:"blankUserData.project_id"}}),_c('small',{ref:"errorText1",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"User Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"用户角色","label-for":"user-role"}},[_c('v-select',{attrs:{"options":_vm.roleOptions,"reduce":function (role) { return role.id; },"state":errors.length > 0 ? false : null,"label":"name"},model:{value:(_vm.blankUserData.role_id),callback:function ($$v) {_vm.$set(_vm.blankUserData, "role_id", $$v)},expression:"blankUserData.role_id"}}),_c('small',{ref:"errorText2",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","type":"submit","variant":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t("Submit"))+" ")]),_c('b-button',{attrs:{"size":"sm","type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }